import 'mapbox-gl/dist/mapbox-gl.css'
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
import mapboxgl from 'mapbox-gl'
import { Power3 } from 'gsap'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import MapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
import styled from 'styled-components'
import { LocalizedNavLink } from '../../../modules'

import Pin from '../../shared/Pin'

mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibWFyaW5vc3phayIsImEiOiItdjZkVFQwIn0.Fb4w2Uwb-fzwvkhKAHUdqg'
const MAPBOX_STYLE_URL =
  'mapbox://styles/marinoszak/ck41vitwf0e9e1cqprlgv8ubg?optimize=true'
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
const Map = (props) => {
  const {
    project,
    selectedId,
    projects,
    hoveredId,
    onMouseEnter,
    onMouseLeave,
    setProject,
    windowSize: { height },
    width,
  } = props

  const [viewport, setViewport] = useState({
    latitude: 38.695897,
    longitude: 20.644474,
    zoom: 10,
    bearing: 0,
    pitch: 15,
    width,
    height,
  })
  const [settings] = useState({
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
    maxZoom: 12,
    minPitch: 0,
    maxPitch: 85,
    dragToRotate: false,
  })

  const flyToLocation = ({ longitude, latitude }) => {
    setViewport({
      longitude,
      latitude,
      zoom: 12,
      pitch: 15,
      transitionInterpolator: new FlyToInterpolator(),
      transitionDuration: 2000,
      transitionEasing: Power3.easeInOut,
    })
  }

  const firstUpdate = useRef(true)
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setViewport({ ...viewport, width, height })
  }, [width, height])

  useEffect(() => {
    if (selectedId && project && project.coordinates) {
      flyToLocation({
        longitude: project.coordinates.longitude,
        latitude: project.coordinates.latitude,
      })
    }
  }, [selectedId])

  const renderMarker = (project, index) => {
    const { longitude, latitude } = project.coordinates
    return (
      <Marker key={index.toString()} longitude={longitude} latitude={latitude}>
        <LocalizedNavLink to={`lefkas/map/projects/${project.slug}`}>
          <Pin
            active={project.slug === selectedId || project.slug === hoveredId}
            size={20}
            onMouseEnter={() => onMouseEnter(project.slug)}
            onMouseLeave={() => onMouseLeave(null)}
            onMarkerClick={() => setProject(project.slug)}
          />
        </LocalizedNavLink>
      </Marker>
    )
  }
  return (
    <Wrapper>
      <MapGL
        {...viewport}
        {...settings}
        mapStyle={MAPBOX_STYLE_URL}
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        {projects.map((project, index) =>
          project.coordinates ? renderMarker(project, index) : null,
        )}
      </MapGL>
    </Wrapper>
  )
}

Map.defaultProps = {
  hoveredItem: '',
  hoveredId: '',
  selectedId: '',
  currentItem: '',
  project: {},
}

Map.propTypes = {
  hoveredItem: PropTypes.string,
  hoveredId: PropTypes.string,
  selectedId: PropTypes.string,
  currentItem: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  setProject: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      longitude: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
    }),
  }),
}

export default Map
